import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../environments/environment';
import { AuthenticateService } from './authenticate/authenticate.service';
import { AppService } from './app.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BnNgIdleService } from 'bn-ng-idle';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss', '../assets/stylesheets/form.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  showContent = true;
  @ViewChild('custSpinner', {read: ElementRef}) custSpinner: ElementRef; 


  isMobileandTabView = window.innerWidth > 320 && window.innerWidth <= 800;
  timeoutInterval = 1800;
  spinner = false;
  openCallMsg = false;
  openScheduleMsg = false;
  openColumnsPopup = false;
  state = false;
  callid = 0;
  moduleType = 0;
  list = [];
  customSpinnerHTML:any;
  customSpinner = false;
  spinnerSub: Subscription;
  customSpinnerSub: Subscription;
  watchSub: Subscription;
  sideBarStateSource: Subscription;
  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public appService: AppService,
    readonly authService: AuthenticateService,
    readonly router: Router,
    readonly bnIdle: BnNgIdleService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    private cd: ChangeDetectorRef
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  signOut() {
    this.authService.logout().subscribe(() => {
      const remD = localStorage.getItem('rem_d_planner')
      const auth = localStorage.getItem('auth')
      localStorage.setItem("optplanner_user", null);
      localStorage.setItem('rem_d_planner', remD);
      localStorage.setItem('auth', auth);
      this.router.navigate(["/login"]);
      this.appService.updateSidebarView(false);
    });
  }

  ngOnInit() {
      if (environment.production) {
      this.watchSub = this.bnIdle.startWatching(this.timeoutInterval)
        .subscribe((isTimedOut: boolean) => {
          if (isTimedOut) {
            this.bnIdle.stopTimer();
            this.signOut();
          }
        });
    }
    this.route.queryParams.subscribe((params) => {
      if (params.state && (params.error !== "access_denied")) {
       this.performGoogleAuthOp(params);
      }
    });
    this.subscribeEvents();
  }

  ngAfterViewInit(){
    this.customSpinnerHTML = this.custSpinner.nativeElement.innerHTML;
    this.cd.detectChanges();
  }


  subscribeEvents() {
    this.appService.callpopup.subscribe(res => {
      this.openCallMsg = res.state;
      this.callid = res.callid;
      this.moduleType = res.moduleType;
      this.list = res.list;
    });
    this.appService.schedulepopup.subscribe(res => {
      this.openScheduleMsg = res;
    });
    this.sideBarStateSource = this.appService.sideBarStateSource$.subscribe(res => {
      this.state = res;
    });
    
    // setTimeout(() => {
      this.spinnerSub = this.appService.loaderState$.subscribe((state: any) => {
        this.spinner = state;
      });
      this.customSpinnerSub = this.appService.customloaderState$.subscribe((state: any) => {               
        if(state.hasOwnProperty('message')){
          let data = '';
          let FinalData = '';
          if(state.state){
            setTimeout(() => {
              this.customSpinner = state.state;
            }, 0);
            if(!this.customSpinner){
              this.custSpinner.nativeElement.innerHTML = `<div aria-busy="true" class="ui-progress-spinner" role="alert" ng-reflect-klass="ui-progress-spinner">
              <svg class="ui-progress-spinner-svg" viewBox="25 25 50 50" style="animation-duration: 0.5s;">
                <circle class="ui-progress-spinner-circle" cx="50" cy="50" r="20" stroke-miterlimit="10" fill="none" stroke-width="3"></circle>
              </svg>
            </div>`;
              data = this.custSpinner.nativeElement.innerHTML.split('</svg>');
              FinalData = data[0]+'</svg><span>'+state.message+
              '<span class="dateLoader"><span class="loader__dot_app">.</span>'+
              '<span class="loader__dot_app">.</span>'+
              '<span class="loader__dot_app">.</span></span></span>'+data[1];
    
              this.custSpinner.nativeElement.innerHTML = FinalData;
            }
          }
        }else{
          if(!state){
            this.custSpinner.nativeElement.innerHTML = this.customSpinnerHTML;
          }
            setTimeout(() => {
              this.customSpinner = state;
            }, 0);
        }        
      });
    // }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileandTabView = window.innerWidth > 320 && window.innerWidth <= 800;
  }

  handleRefresh(event) {
    this.showContent = false;
    setTimeout(() => {
      this.showContent = true;
      event.target.complete();
    }, 500);
  }

  performGoogleAuthOp(params) {
  }

  ngOnDestroy(): void {
    if (this.spinnerSub) {
      this.spinnerSub.unsubscribe();
    }
    if (this.customSpinnerSub) {
      this.customSpinnerSub.unsubscribe();
    }
    if (this.watchSub) {
      this.watchSub.unsubscribe();
    }
    this.sideBarStateSource && this.sideBarStateSource.unsubscribe();
  }
}
