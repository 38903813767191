import { environment } from './../environments/environment';

let url = environment.basePath;
let urlV2 = environment.basePathV2;
if (environment.production) {
    url = `${window.location.protocol}//${window.location.hostname}/api/v1/`
}
export const config = {
    baseUrl: `${url}file/`,
    login: `${url}user/login`,
    refreshlogintokenurl: `${url}user/refreshToken`,
    signUp: `${url}user/signup`,
    forgetPassword: `${url}users/forgot-password?email=:email`,
    resetPassword: `${url}users/reset-password`,
    activateUser: `${url}activate-email`,
    logout: `${url}user/logout`,
    verifyEmail: `${url}user/verify-email`,
    verifyUserEmail: `${url}user/verify-user-email`,
    sampleUrl: `${url}tenant/get-tenant-process/:id/?page=:page&limit=:limit`,
    addclinic: `${url}clinic`,
    getclinicTable: `${url}clinic/?pageNo=:page&limit=:limit`,
    getSkillTable: `${url}skills/?pageNo=:page&limit=:limit`,
    editlinic: `${url}clinic`,
    getSelectedClinic: `${url}clinicUser`,
    deleteclinic: `${url}clinic/`,
    usernotification:`${url}notification`,
    addEditSkills: `${url}skills`,
    deleteskills: `${url}skills`,
    getGroups: `${url}groups`,
    getPlaceOfServiceURL: `${url}type/placeOfService`,
    // Therapygroup
    addEdittherapygroup: `${url}grouptherapy/group`,
    deletetherapygroup: `${url}grouptherapy/group`,
    getgrouptherapygroups: `${url}grouptherapy/group/getAll?pageNo=:page&limit=:limit`,

    // contracts Api
    addContract: `${url}contract`,
    getContractTable: `${url}contract/?pageNo=:page&limit=:limit`,
    getContractListTable: `${url}contract/`,
    deleteContract: `${url}contract/`,

    // spots Api
    getSpotTable: `${url}spots/?pageNo=:page&limit=:limit`,
    addEditSpot: `${url}spots`,
    deleteSpot: `${url}spots`,

    // planner Api
    getPlannerTable: `${url}planner/?pageNo=:page&limit=:limit`,
    addEditPlanner: `${url}planner`,
    deletePlanner: `${url}planner`,

    // provider Api
    getProviderTable: `${url}provider/?sortBy=name&pageNo=:page&limit=:limit`,
    addEditProvider: `${url}provider`,
    getProviderAdditionalData: `${url}provider/providerDetail`,
    addEditProviderStatus: `${url}user/status`,
    deleteProvider: `${url}provider`,
    deleteProviderTeam: `${url}patient-team`,
    deleteUnavailability: `${url}unavailability/deleteUnavailability`,
    deleteUnavailabilityGet: `${url}unavailability`,
    pendingUnavailabilityGet: `${url}unavailability/pending`,
    patientPastDataGet: `${url}patient/pastData/`,

    deleteUnavailabilityGetReason: `${url}type/cancellingReasonType`,
    allSpecialSession: `${url}unavailability/allSpecialSession`,
    providerUnavailability: `${url}unavailability/all`,
    providerUnavailabilityApprovel: `${url}unavailability/unavailabilityApproval`,
    providerSkillsURL: `${url}provider/providerSkills`,
    patientSkillsURL: `${url}patient/skill`,
    typeJobTitleURL: `${url}type/jobTitle`,

    // imageUpload Api 
    uploadImage: `${url}file?type=:type`,

    // resetPassword Api
    resetPasswordGet: `${url}user/forget-password?email=:email`,
    resetPasswordPut: `${url}user/reset-password`,

    // patientApi
    addEditPatient: `${url}patient`,
    cancelPatientSchedule: `${url}scheduling/cancelPatientSchedule`, 
    getPatientTable: `${url}patient?sortBy=name&pageNo=:page&limit=:limit`,
    clinicWisePatientInfo: `${url}patient/clinicWisePatientInfo`,
    getPatientNotAssignedTable: `${url}provider/notAssignedPatients/`,
    clinicWiseProviderInfo: `${url}provider/clinicWiseProviderInfo`,
    deletePatient: `${url}patient`,

    // unavailabilty
    addEditunavailability: `${url}unavailability`,
    addEditScheduling: `${url}scheduling`,
    getSchedulingSessionCount: `${url}scheduling/count`,

    deleteSolverSession: `${url}solver/publishedSession`,
    addEditSolver: `${url}solver/create`,
    getProviderSchedule: `${url}solver/providerSchedules`,
    getProviderUnavailabilitySchedule: `${url}unavailability/allUnavailabilityForClinic`,
    getScheduleholidays: `${url}holidays`,
    getScheduleUpcomingSessions: `${url}patient`,
    addEditSolverNew: `${url}solver`,
    solverTargetSession : `${url}solver/targetSessions`,
    exportDocsDMS: `${url}sendSchedule/provider`,
    replanSolver: `${url}solver/rePlan`,
    assignedProvder: `${url}solver/changeAssignedSession`,
    swapSessionURL: `${url}solver/swapSessions`,
    deleteSolver: `${url}solver/`,
    deleteProblemset: `${url}solver/problemSet`,
    getAllSolver: `${url}solver`,
    getProblemSet: `${url}solver/getProblemSet`,
    updateProblemSetStatus: `${url}solver/updateProblemSetStatus`,
    approveSolverProblemSet: `${url}solver/approveProblemSets`,
    ProblemSet: `${url}solver/getAllEscalatedSession`,
    problemSetSingleData: `${url}solver/getProblemSetTypeData`,
    // skillType
    getSkillType: `${url}type/skill`,

    //    Patient team Api
    addEditPatientTeam: `${url}patient-team`,
    getPatientTeam: `${url}patient-team/getAll?pageNo=:page&limit=:limit`,
    deletePatientTeam: `${url}patient-team/provider`,
    providerPatientTeam: `${url}patient-team/provider`,
    getSetProfile: `${url}user/userProfileDetails`,
    changePassword: `${url}user/changePassword`,
    primaryProvider:`${url}patient-team/assignPrimarySupervisor`,

    // Dashboard counts get api 
    getProviderPatientcount:`${url}dashboard/providerPatientCount`,
    getUpdatedClinicsList: `${url}clinic/userAssignedClinics`,
    getTodayUnavalityList: `${url}dashboard/unavailability`,
    getPlannedSessionList: `${url}dashboard/plannedSession`,
    getEsclatedSessionList: `${url}dashboard/escalatedShift`,


    // gendercountdashboard
    getAllgendercount:`${url}dashboard/genderCount`,

    // getPlannedunPlannedShift
    getShiftPlanndUnplanned:`${url}dashboard/plannedUnplannedShiftCount`,
    getCheckProviderUnailabelity:`${url}unavailability/checkProviderUnavailability`,
 
    // getLoggedUserTypesInCurrentClinic
    getLoggedUserTypesInCurrentClinic:`${url}user/userTypes`,
    getUserTypesByID:`${url}user/userType`,
    loginRole:`${url}user/loginRole/`,

   //holidays

   holidays : `${url}holidays`,

    // Version 2 URL

    // Patient V2
    patientAuthorizationDataGET : `${urlV2}authorization/patient`,
    patientScheduledInfoDataGET : `${url}patient/`,

    // Setting V2
    settingDataGet: `${urlV2}clinicSetting`,

    // Job Config V2
    jobConfigDataGet: `${url}type/jobTitle`,
    jobConfigureDetailGet: `${url}jobTitleConfig`,
    addjobConfigureSkills: `${url}jobTitleConfig`,


    // trash feature 
    getTrashUrl:`${url}trash/allDeleted`,
    restoreData:`${url}trash/restore/`,
    delPermanently:`${url}trash/`,

    // Authorisation
    addAuth:`${urlV2}authorization/patient/`,
    editAuth:`${urlV2}authorization/update/patient/`,
    delAuth:`${urlV2}authorization/delete/patient/`

};
