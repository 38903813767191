import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppExService {

  callActivityData = []
  
  getFilteredClinicList = new Subject<any>();
  getFilteredProviderpatientcount = new Subject<any>();
  getCustomReoccurData = new Subject<any>();
  getLanguageOptions = new BehaviorSubject({ lang: "EN" });

  callNoteAdded = new Subject<any>();
  sourceCallNoteAdded$ = this.callNoteAdded.asObservable();

  folderAdded = new Subject<any>();
  sourcefolderAdded$ = this.folderAdded.asObservable();

  fileAdded = new Subject<any>();
  sourcefileAdded$ = this.fileAdded.asObservable();

  CalendarToRerender = new Subject<any>();
  CalendarToRerender$ = this.CalendarToRerender.asObservable();

  constructor() { }

  updateCallNotes(data) {
    this.callNoteAdded.next(data);
  }

  updateFolderAdded(data) {
    this.folderAdded.next(data);
  }

  updateFileAdded(data) {
    this.fileAdded.next(data);
  }

  UpdateCalendarToRender(data){
    this.CalendarToRerender.next(data);
  }
  
}
