import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
currentUser = new BehaviorSubject('');
userTypeID:any;
constructor(public sharedService: SharedService) { }
remD = JSON.parse(localStorage.getItem('optplanner_user'));

updateUnAvailability(formData){
  this.currentUser.subscribe((res:any) =>{
    this.userTypeID = res.userType.id;
  })
  return this.sharedService.putData(config.addEditunavailability+`?userTypeId=${this.userTypeID}`,formData)
}
addUnAvailability(formData){
  this.currentUser.subscribe((res:any) =>{
    this.userTypeID = res.userType.id;
  })
  return this.sharedService.postData(config.addEditunavailability+`?userTypeId=${this.userTypeID}`,formData)
}


}
