export const MainModuleList = {
    "users": "user_accounts",
    "subscription": "subscription",
    "customer": "customers",
};

export const UserModuleList = {
   "user-accounts" : "user_accounts",
};

export const SubscriptionModuleList = {
   "all-subscription": "all_subscriptions",
   "all-plan": "subscription_plans",
   "subscription-product": "subscription_products",
   "subscription-quotation": "subscription_quotations",
   "sales-team": "sales_teams",
   "invoicing": "invoicing",
   "subscription-tags": "tags",
   "payment-gateway": "payment_gateway",
   "payment": "payments",
   "plan-permissions": "plan_template",
   "roles-for-plan": "roles_for_plan",
   "all-tenants": "all_tenants",
};

export const CustomerModuleList = {
   "all-customers" : "all_customers",
};
