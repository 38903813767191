import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticateService } from '../authenticate.service';
import { Password } from '../autheticate.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss','./../authenticate.scss'],
})
export class ResetPasswordComponent implements OnInit {
passwordModel: Password;
invalid = false;
newPassword: '';
email: '';
confirmPasswords : '';
"user_id" : number;
hash: string;
password: string;
// isconfirmTextFieldType: boolean;
isTextFieldType:boolean;
isTextFieldConfirm:boolean;
mailSent = false;
  constructor(
    public route: ActivatedRoute,
    public authService: AuthenticateService,
    public router: Router,
    public messageService: MessageService,
  ) {
  }
  ngOnInit() {
    this.passwordModel = new Password();
    this.route.params.forEach((params: Params) => {
      this.user_id = params['user_id'];
      this.hash = params['hash'];
      this.password = params['password'];
    });
  }
 
  resetPassword() {
    let data = {
      token: this.user_id,
      password: this.newPassword,
      confirmPassword: this.confirmPasswords,
    }
    this.authService.resetPassword(data).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'Succes', detail: "Password has been reset succussfully."});
        this.router.navigate(['/login']);
      }, (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message});
      });
  }

  toLogin(){
    this.mailSent = false;
    setTimeout(() => {
    }, 0);
  }

  togglePasswordFieldType(){
    this.isTextFieldType = !this.isTextFieldType;
  }
  togglePasswordFieldConfirm(){
    this.isTextFieldConfirm = !this.isTextFieldConfirm;
  }
}
