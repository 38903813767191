import { SharedService } from './../shared/shared.service';
import { config } from './../config';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    public sharedService: SharedService
  ) { }

  getSelectedClinic(id): Observable<any> { 
    const url = `${config.getSelectedClinic}`;
    return this.sharedService.getData(url);
  }
  setSelectedClinic(data): Observable<any> { 
    const url = `${config.getSelectedClinic}/${data.userID}/${data.clinicID}`;
    return this.sharedService.postData(url, {});
  }
}

