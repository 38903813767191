<header *ngIf="router.url !=('/login')">
  <div class="row m-0 w-100 header-hamburger" style="align-items: center;box-shadow: 0 2px 2px rgb(0 0 0 / 10%);">
    <div class="col-md-12">

      <div class="hamburger-wrapper">
        <div style="display: flex;" class="hamburger-wrapper-firstDiv">
          <button (click)="showSidebar();" class="hamburger mr-3">
            <span></span>
            <span></span>
            <span class="mb-0"></span>
          </button>
          <div class="header-inner-left">
            <div class="welcome" style="min-width: 300px;display: flex;flex-direction: column;height: fit-content;">
              <h6 class="heading">
                <span *ngIf="pageData.subName === 'Welcome to Therapy & Beyond.'">
                  {{greetMessage}},
                </span> 
                <!-- <span>{{pageData.name || (userData?.firstName + " " + userData?.lastName) | titlecase}}</span> -->
                <span>{{pageData.name || truncatedFullName | titlecase}}</span>
              </h6>
              <p *ngIf="pageData.subName === 'Welcome to Therapy & Beyond.'">
                {{pageData.subName | titlecase}}
                <!-- <span [routerLink]="['/dashboard']" style="cursor: pointer;">Home</span> -->
              </p>
              <p *ngIf="pageData.subName !== 'Welcome to Therapy & Beyond.'" style="margin-bottom: 0px;" class="headerLen">
                  <span [routerLink]="['/dashboard']" style="cursor: pointer;">Home</span>
                <span class="" [routerLink]="['/provider/all-provider']"
                  *ngIf="router.url.includes('/provider/provider-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">All Providers</span>
                </span>

                <span class="" [routerLink]="['/patient']"
                  *ngIf="router.url.includes('/patient/patient-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">All Patients</span>
                </span>

                <span class="" [routerLink]="['/solver/all-solver']"
                  *ngIf="router.url.includes('/all-solver/solver-plan-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">All Solver</span>
                </span>
                <span class="" [routerLink]="['/solver/individualgroup-solver']"
                  *ngIf="router.url.includes('/individualgroup-solver/solver-plan-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">Individual & Group Solver</span>
                </span>
                <span class="" [routerLink]="['/solver/bcba-solver']"
                  *ngIf="router.url.includes('/bcba-solver/solver-plan-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">BCBA Solver</span>
                </span>
                <span class="" [routerLink]="['/solver/ot-solver']"
                  *ngIf="router.url.includes('/ot-solver/solver-plan-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">OT Solver</span>
                </span>
                <span class="" [routerLink]="['/solver/speech-solver']"
                  *ngIf="router.url.includes('/speech-solver/solver-plan-details')" style="position: relative;cursor: pointer;
                  padding-right: 2px;">
                  / <span style="color: #818FE2;">Speech Solver</span>
                </span>

                <span class="bread-crumb headerCss"
                  [ngStyle]="{'left': (router.url.includes('/solver/solver-plan-details') ||  router.url.includes('/provider/provider-details') || router.url.includes('patient/patient-details'))  ? '140px' : '50px'}">
                    / <span style="color: #818FE2;">{{pageData.subName}}</span>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex;align-items: center;">
          <div class="hospital-dropdown right-header">
            <div *ngIf="!isMobileView && !isTabView" class="vaildation-area p-dropdown-top hederDrop mainHeader alignWithBell" style="display: flex;">
              <p-dropdown (onChange)="onClinicChange()" [(ngModel)]="selectedClinic"
              [ngClass]="{ 'disabled-dropdown': shouldDisableDropdown(), 'hide-dropdown': shouldHideDropdown() }"
              [disabled]="shouldDisableDropdown()" *ngIf="(!(
              router.url.includes('/holidays/all-holidays')
              || router.url.includes('/clinics/all-clinics')
              || router.url.includes('/user/my-profile')
              || router.url.includes('/user/notifications')
              || (userData?.userType.type === 'provider' && router.url.includes('/availability/all-availability'))
              || (userData?.userType.type === 'provider' && router.url.includes('scheduling/all-scheduling'))
              || router.url.includes('/job-config/all-job-config')
              ))"
               filter="true"
                styleClass="hederDrop mainHeader" panelStyleClass="headerDropPanel" name="groups" [options]="userData?.userType.type === 'provider' || userData?.userType.type === 'planner' ? transformedData : listClinic"
                 appendTo="body" emptyFilterMessage="No Clinic Found">
              </p-dropdown>
            </div>

            <div class="vaildation-area p-dropdown-top hederDrop mainHeader alignWithBell" style="display: flex;" *ngIf="isMobileView || isTabView">
              <p-dropdown (onChange)="onClinicChange()" [(ngModel)]="selectedClinic"
              *ngIf="(!(router.url.includes('/clinics/') 
              || router.url.includes('/user/all-clinics')
              || router.url.includes('/user/notifications')
              || router.url.includes('/user/my-profile')
              || (userData?.userType.type === 'provider' && router.url.includes('/availability/all-availability'))
              || (userData?.userType.type === 'provider' && router.url.includes('scheduling/all-scheduling'))
              || router.url.includes('/patient/patient-details')
              || router.url.includes('/job-config/all-job-config')
              || router.url.includes('/provider/provider-details')
              || router.url.includes('/solver/solver-plan-details')
              || router.url.includes('/solver/all-solver/solver-plan-details')
              || router.url.includes('/solver/individualgroup-solver/solver-plan-details')
              || router.url.includes('/solver/bcba-solver/solver-plan-details')
              || router.url.includes('/solver/ot-solver/solver-plan-details')
              || router.url.includes('/solver/speech-solver/solver-plan-details')
              || router.url.includes('/holidays/all-holidays')) && listClinic.length >= 1)" 
               filter="true" emptyFilterMessage="No Clinic Found"
                styleClass="hederDrop mainHeader" panelStyleClass="headerDropPanel" name="groups" [options]="userData?.userType.type === 'provider' || userData?.userType.type === 'planner' ? transformedData : listClinic"
                 appendTo="body">
                <ng-container *ngIf="isMobileView">
                  <ng-template let-item pTemplate="selectedItem">
                    <em style="font-size: 16px;" class="{{item.label ? 'fa fa-filter' : 'fa fa-filter'}}"></em>
                  </ng-template>
                </ng-container>
              </p-dropdown>


            </div>

            <!-- <div style="margin-left: 2%;">
            </div> -->
            <div *ngIf="false" class="LangDrop">
              <p-dropdown (onChange)="appExService.getLanguageOptions.next({lang:selectedLanguage})"
                [(ngModel)]="selectedLanguage" [options]="listLanguage" placeholder="Select" appendTo="body">
              </p-dropdown>
            </div>
          </div>

          <ng-container  *ngIf="isMobileView">
            <div class="m-notification-userProfile">
              <div appClickedOutside (clickedOutside)="handel()" class="m-notification-userProfile-Firstdiv" *ngIf="!router.url.includes('/user/notifications')">
                <div *ngIf="notificationCountshow > 0 && notificationCountshow <= 9" class="nbell"> {{notificationCountshow}} </div>
                <div *ngIf="notificationCountshow > 9" class="nbell"> 9+ </div>
                <div *ngIf="notificationCountshow == 0" class="bell3"></div>
                <div class="bell-icon bellAlign" (click)="shakeIcon()" style="width: 20px">
                  <!-- <div class="notificationIconDiv"> -->
                  <img (click)="showNotificationWrapper();" style="cursor: pointer; width: 17px;"
                    [src]="'../../../assets/icon/notification.png'" alt="">
                    
                    <!-- <img (click)="showNotificationWrapper();" class="user-down notificationBell" style="cursor: pointer; width: 17px ; margin-top: 0.5%;"
                    [src]="'../../../assets/icon/notification.png'" alt=""> -->
                </div>
                
        
                <div *ngIf="notificationWrapper"  style="width: 50%; min-height: 50px; position: relative; z-index: 20000;"
                  class="notification-panel  signout-wrapper d-flex justify-content-between align-items-center">
                  <ul style="width: 100%;margin-bottom: 0px;">
                    <li class="notifHead">
                      <span><em class="far fa-flag mr-2"></em> Notifications </span>
                      <span *ngIf="notificationCountshow > 0" style="position: absolute;right: 15px;color: #fefefe;font-size: 14px;
                        margin-top: 2px;word-spacing: -1px;cursor: pointer;" class="markAll" (click)="markAllRead()">Mark all as read</span>
                    </li>
                    <li>
                      <ng-container *ngIf="notificationMsg.length > 0">
                        <ul class="scrollable">
                          <li *ngFor=" let itemsnoti of notificationMsg ">
                            <div class="p-2 pl-3 notiDiv" [ngClass]="{'notiDivUnread unvisited': itemsnoti.status == 'NEW' }" (click)="notifStatusUpdate(itemsnoti.id)">
                              <span class="notimsg">
                                {{itemsnoti.message}}
                              </span>
                              <span class="notidate">
                                {{itemsnoti.notificationDateTime | date: 'MMM d, y, HH:mm a'}}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </ng-container>
        
                      <ng-container *ngIf="notificationMsg.length == 0">
                        <ul class="scrollable">
                          <li >
                            <div class="p-2 pl-4 notiDiv" style="text-align: center;">You Don't Have Any Notifications.</div>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                    <li *ngIf="totalCountNotifications > 5" class="notifFooter">
                      <div (click)="showAllNotifications()">
                        Show All
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-10 profile-div" style="padding: 10px 0px 0px 0px;width: 55px;">
                <span style="cursor: pointer;height: fit-content;display: flex;justify-content: space-evenly;" (click)="clickedOutside($event)" data-toggle="dropdown">
                  <!-- <img *ngIf="!url" class="user-initial" [src]="'../../../assets/images/user.svg'" alt=""> -->
                  <div *ngIf="!url" class="NoImgUserProfile">
                    {{ userData?.firstName.charAt(0) | titlecase }}{{userData?.lastName.charAt(0) | titlecase}}
                  </div>
                  <img *ngIf="url" class="user-initial" [src]="imgBaseURL+url" alt="">
                  <!-- <div style="cursor: pointer;color: black;width: 55px !important;text-overflow: ellipsis;white-space: nowrap;font-size: 10px;">
                    {{(userData?.firstName + " " + userData?.lastName) | titlecase}}
                  </div> -->
                  <span style="position: relative;cursor: pointer;">

        
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-down dropUp" style="margin-left: 5px;"
                      viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        stroke-width="0.9" stroke="#000" fill="#000"> </path>
                    </svg>
          
                  </span>
                </span>
                <!-- <span style="text-align: right;" data-toggle="dropdown">
                  <div  [ngClass]="{'providerRole': (
                    (userData?.userType.type === 'provider') ||
                    (userData?.userType.type === 'manager') ||
                    (userData?.userType.type === 'planner')
                    )}" class="adminStyle" style="padding-right: 0px;position: relative;bottom: 16px;font-size: 10px;text-align: left;left: -3%;">
                    {{userData?.userType.type | titlecase}}
                  </div>
                </span> -->
      
                <div class="dropdown-menu">
        
                  <div style="width: 100%;" class="signout-wrapper d-flex justify-content-between align-items-center">
                    <ul style="width: 100%;    margin-bottom: 0;">
                      <li *ngIf="userData?.userType.type != 'superAdmin'" style="
                      padding-top: 10px;
                      padding-left: 10px;
                      cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                      padding-bottom: 10px;">
                        <p-dropdown (click)="roleDropDownClick($event)" (onChange)="changeUserRole($event)"
                          [(ngModel)]="userMutiRoleTypeModel" styleClass="multiRoleDropDown" panelStyleClass="multiRoleDropDownPanel" [options]="userMutiRoleTypeList" placeholder="Select" appendTo="body">
                        </p-dropdown>
                        </li>
                      <li class="hvrColor" (click)="callEditPage({})" style="
                    padding-top: 10px;
                    padding-left: 10px;
                    cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                    padding-bottom: 10px;">
                        My Profile
                      </li>
                      <li class="hvrColor" (click)="callUpdatePassword({})" style="
                      padding-top: 10px;
                      padding-left: 10px;
                      cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                      padding-bottom: 10px;">
                        Update Password
                      </li>
                      <li (click)="signOut()" class="hvrColor" style="
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    cursor: pointer;color: #656565;padding-top: 10px;">
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
        
              </div>
            </div>
          </ng-container>

          <ng-container  *ngIf="!isMobileView">
            <div>
              <div style="display: flex;align-items: center;">
                <div class="d-notification-userProfile" *ngIf="!router.url.includes('/user/notifications')">
                  <div appClickedOutside (clickedOutside)="handel()">
                    <div *ngIf="notificationCountshow > 0 && notificationCountshow <= 9" class="nbell"> {{notificationCountshow}} </div>
                    <div *ngIf="notificationCountshow > 9" class="nbell"> 9+ </div>
                    <div *ngIf="notificationCountshow == 0" class="bell2"></div>
                    <div class="bell-icon" (click)="shakeIcon()">
                      <img id="bellIcon" (click)="showNotificationWrapper();" class="forDesk" style="cursor: pointer; width: 23px;"
                        [src]="'../../../assets/icon/notification.png'" alt="">
                    </div>
            
                    <div *ngIf="notificationWrapper"  style="width: 50%; min-height: 50px; position: relative; z-index: 20000;"
                      class="notification-panel  signout-wrapper d-flex justify-content-between align-items-center">
                      <ul style="width: 100%;margin-bottom: 0px;">
                        <li class="notifHead">
                          <span><em class="far fa-flag mr-2"></em> Notifications </span>
                          <span *ngIf="notificationCountshow > 0" style="position: absolute;right: 15px;color: #fefefe;font-size: 14px;
                            margin-top: 2px;word-spacing: -1px;cursor: pointer;" class="markAll" (click)="markAllRead()">Mark all as read</span>
                        </li>
                        <li>
                          <ng-container *ngIf="notificationMsg.length > 0">
                            <ul class="scrollable">
                              <li *ngFor=" let itemsnoti of notificationMsg ">
                                <div class="p-2 pl-3 notiDiv" [ngClass]="{'notiDivUnread unvisited': itemsnoti.status == 'NEW' }" (click)="notifStatusUpdate(itemsnoti.id)">
                                  <span class="notimsg">
                                    {{itemsnoti.message}}
                                  </span>
                                  <span class="notidate">
                                    {{itemsnoti.notificationDateTime | date: 'MMM d, y, hh:mm a'}}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </ng-container>
            
                          <ng-container *ngIf="notificationMsg.length == 0">
                            <ul class="scrollable">
                              <li >
                                <div class="p-2 pl-4 notiDiv" style="text-align: center;">You Don't Have Any Notifications.</div>
                              </li>
                            </ul>
                          </ng-container>
                        </li>
                        <li *ngIf="totalCountNotifications > 5" class="notifFooter">
                          <div (click)="showAllNotifications()">
                            Show All
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
        
               <ng-container *ngIf="!isTabView">
                <div class="" style="padding-left: 0px;">
                  <span style="cursor: pointer;height: fit-content;display: flex;justify-content: space-between;" data-toggle="dropdown" (click)="closeNotificationWrapper()">
                    <!-- <img *ngIf="!url" class="user-initial" [src]="'../../../assets/images/user.svg'" alt=""> -->
                    <img *ngIf="url" class="user-initial" [src]="imgBaseURL+url" alt="">
                    <div *ngIf="!url" class="NoImgUserProfile">
                      {{ userData?.firstName.charAt(0) | titlecase }}{{userData?.lastName.charAt(0) | titlecase}}
                    </div>
                    <div class="profileName" style="cursor: pointer;color: black;width: auto;margin: 0px 5px;" >
                      <label  style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-width: 126px;font-size: 14px;color: #000000;margin-bottom: 0px;cursor: pointer;">
                        {{(userData?.firstName + " " + userData?.lastName) | titlecase}}
                        <br>
                        <span [ngClass]="{'providerRole': (
                            (userData?.userType.type === 'provider') ||
                            (userData?.userType.type === 'manager') ||
                            (userData?.userType.type === 'planner')
                            )}" class="adminStyle" style="color: #656565;">
                            {{userData?.userType.type | titlecase}}
                        </span>
                      </label>
                    </div>
                    <span style="position: relative;cursor: pointer;margin-top: -4px;">
          
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-down"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          stroke-width="0.9" stroke="#000" fill="#000"> </path>
                      </svg>
            
                      <!-- <img class="user-down"  
                      style="cursor: pointer; width: 17px ; margin-top: 0.5%;"  
                      [src]="'../../../assets/images/down-arrow-svgrepo-com.svg'" alt=""> -->
                    </span>
                  </span>
        
                  <div class="dropdown-menu boxStyle">
          
                    <div style="width: 100%;" class="signout-wrapper d-flex justify-content-between align-items-center">
                      <ul style="width: 100%;    margin-bottom: 0;">
                      <li *ngIf="userData?.userType.type != 'superAdmin'" style="
                      padding-top: 10px;
                      padding-left: 10px;
                      cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                      padding-bottom: 10px;">
                        <p-dropdown (click)="roleDropDownClick($event)" (onChange)="changeUserRole($event)"
                          [(ngModel)]="userMutiRoleTypeModel" styleClass="multiRoleDropDown" panelStyleClass="multiRoleDropDownPanel" [options]="userMutiRoleTypeList" placeholder="Select" appendTo="body">
                        </p-dropdown>
                        </li>
                        <li class="hvrColor" (click)="callEditPage({})" style="
                      padding-top: 10px;
                      padding-left: 10px;
                      cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                      padding-bottom: 10px;">
                          My Profile
                        </li>
                        <li class="hvrColor" (click)="callUpdatePassword({})" style="
                        padding-top: 10px;
                        padding-left: 10px;
                        cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                        padding-bottom: 10px;">
                          Update Password
                        </li>
                        <li (click)="signOut()" class="hvrColor" style="
                      padding-top: 10px;
                      padding-bottom: 10px;
                      padding-left: 10px;
                      cursor: pointer;color: #656565;padding-top: 10px;">
                          Logout
                        </li>
                      </ul>
                    </div>
                  </div>
          
                </div>
               </ng-container>
                <ng-container *ngIf="isTabView">
                  <div class="" style="padding-left: 0px;">
                    <span style="cursor: pointer;height: fit-content;display: flex;justify-content: space-between;align-items: center;" data-toggle="dropdown">
                      <!-- <img *ngIf="!url" class="user-initial" [src]="'../../../assets/images/user.svg'" alt=""> -->
                      <img *ngIf="url" class="user-initial" [src]="imgBaseURL+url" alt="">
                      <div *ngIf="!url" class="NoImgUserProfile">
                        {{ userData?.firstName.charAt(0) | titlecase }}{{userData?.lastName.charAt(0) | titlecase}}
                      </div>
                      <!-- <div class="profileName" style="cursor: pointer;color: black;width: auto;margin: 0px 5px;" > -->
                        <!-- <label  style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-width: 126px;font-size: 14px;color: #000000;margin-bottom: 0px;cursor: pointer;">
                          {{(userData?.firstName + " " + userData?.lastName) | titlecase}}
                          <br>
                          <span [ngClass]="{'providerRole': (
                              (userData?.userType.type === 'provider') ||
                              (userData?.userType.type === 'manager') ||
                              (userData?.userType.type === 'planner')
                              )}" class="adminStyle" style="color: #656565;">
                              {{userData?.userType.type | titlecase}}
                          </span>
                        </label> -->
                      <!-- </div> -->
                      <span style="position: relative;cursor: pointer;margin-top: -4px;">
            
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-down"
                          viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            stroke-width="0.9" stroke="#000" fill="#000"> </path>
                        </svg>
                      </span>
                    </span>
          
                    <div class="dropdown-menu">
            
                      <div style="width: 100%;" class="signout-wrapper d-flex justify-content-between align-items-center">
                        <ul style="width: 100%;    margin-bottom: 0;">
                          <li *ngIf="userData?.userType.type != 'superAdmin'" style="
                          padding-top: 10px;
                          padding-left: 10px;
                          cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                          padding-bottom: 10px;">
                            <p-dropdown (click)="roleDropDownClick($event)" (onChange)="changeUserRole($event)"
                              [(ngModel)]="userMutiRoleTypeModel" styleClass="multiRoleDropDown" panelStyleClass="multiRoleDropDownPanel" [options]="userMutiRoleTypeList" placeholder="Select" appendTo="body">
                            </p-dropdown>
                            </li>
                          <li class="hvrColor" (click)="callEditPage({})" style="
                        padding-top: 10px;
                        padding-left: 10px;
                        cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                        padding-bottom: 10px;">
                            My Profile
                          </li>
                          <li class="hvrColor" (click)="callUpdatePassword({})" style="
                          padding-top: 10px;
                          padding-left: 10px;
                          cursor: pointer;border-bottom: 1px solid #656565; color: #656565;border-bottom: 1px solid #656565;
                          padding-bottom: 10px;">
                            Update Password
                          </li>
                          <li (click)="signOut()" class="hvrColor" style="
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-left: 10px;
                        cursor: pointer;color: #656565;padding-top: 10px;">
                            Logout
                          </li>
                        </ul>
                      </div>
                    </div>
            
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

    </div>
  </div>
</header>