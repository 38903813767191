<p-toast></p-toast>
<router-outlet *ngIf="!isMobileandTabView"></router-outlet>
<p-progressSpinner strokeWidth="3" animationDuration=".5s" *ngIf="spinner"></p-progressSpinner>
<p-progressSpinner #custSpinner strokeWidth="3" animationDuration=".5s" [ngClass]="customSpinner ? 'showSpinner' : 'hideSpinner'" ></p-progressSpinner>
<app-table-columns></app-table-columns>
<ion-content class="ion-padding" *ngIf="isMobileandTabView">
    <ion-refresher slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <router-outlet *ngIf="showContent"></router-outlet>
</ion-content>
