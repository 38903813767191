import { EnglishLanguage } from "./en.language";
import { LanguageInterface } from "./interface.language";
import { MexicanLanguage } from "./mex.language";

export const getLanguage = (lang) : LanguageInterface=>{
    switch (lang) {
        case 'EN':
            return EnglishLanguage;
        case 'MEX':
            return MexicanLanguage;
        default:
            break;
    }
}