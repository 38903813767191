import { SharedService } from '../shared/shared.service';
import { config } from '../config';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  constructor(public sharedService: SharedService) { }


  updateClinic(formData) {
    return this.sharedService.putData(config.editlinic, formData);
  }
  updateProfile(formData) {
    return this.sharedService.putData(config.getSetProfile, formData);
  }
  updatePassword(formData) {
    return this.sharedService.putData(config.changePassword, formData);
  }
  getProfile() {
    return this.sharedService.getData(config.getSetProfile);
  }
  addClinic(formData) {
      return this.sharedService.postData(config.addclinic, formData);
  }
  updateClinicHeader(){
    return this.sharedService.getData(config.getUpdatedClinicsList);
  }

  getClinic(data) {
    let url = `${config.getclinicTable}`;
    url = url.replace(":page", data.page).replace(":limit", data.limit);
    if(data.searchParam){
      url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
    }
    if(data.status !== null && data.status !== undefined){
      url = `${url}&status=${data.status}`;
    }
    return this.sharedService.getData(url);
  }

  // getClinicById(id) {
  //   const getClinicByIdUrl = `${config.getclinic}/${id}`;
  //   return this.sharedService.getData(getClinicByIdUrl);
  // }

  deleteClinic(id) {
    return this.sharedService.deleteData(`${config.deleteclinic}${id}`);
  }

  shownotification(){
    const url = `${config.usernotification}/user/all`
    return this.sharedService.getData(url);

  }
  shownotificationAll(payload){
    let url = `${config.usernotification}/user/all?limit=${payload.limit}&pageNo=${payload.pageNo}`;
    if(payload.searchParam){
      url =`${url}&searchParam=${payload.searchParam}`
    }else if(payload.startDate){
      url =`${url}&startDate=${payload.startDate}`
    }
    
    return this.sharedService.getData(url);

  }
  shownotificationOnInitAll(payload){
    let url = `${config.usernotification}/user/all?limit=${payload.limit}&pageNo=${payload.pageNo}&showAll=${payload.showAll}`;
    return this.sharedService.getData(url);

  }
  updateNotificationStatus(id){
    const url = `${config.usernotification}?id=`+id
    return this.sharedService.putIdData(url);

  }
  markAllNotificationStatusRead(){
    const url = `${config.usernotification}?markAsRead=`+true
    return this.sharedService.putIdData(url);

  }
  readAllNotificationAPI(ids){
    const url = `${config.usernotification}/`
    return this.sharedService.putData(url,ids);
  }
  DeleteNotification(id){
    const url = `${config.usernotification}/`+id;
    return this.sharedService.deleteData(url);
  }

}
