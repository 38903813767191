import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../authenticate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', './../authenticate.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  resetEmail: string;
  mailSent = false;
  submitted = false;
  constructor(
    public router: Router,
    public authService: AuthenticateService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
  
   }

  /**
   * To reset password
   * @param email Email
   */
  forgotPassword(form) {
    // email = email.toLowerCase();
    if(form.submitted){
      this.submitted = true
    }
    if(form.form.valid){
    this.authService.forgotPassword(this.resetEmail.toLowerCase()).subscribe((response) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password Recovery email send successfully'});
      this.mailSent = true;
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message});
    });
  }
  }
  tologin(){
    this.mailSent = false;
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 0);
  }

}
