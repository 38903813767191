import { config } from './../config';
import { AppService } from './../app.service';
import { SharedService } from './../shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardMeetingService {
  cachedUserData = null;
  callId = null;

  constructor(
    public sharedService: SharedService,
    readonly appService: AppService,
    readonly http: HttpClient,
  ) { }

  getMyProfileInfo(id): Observable<any> {
    let url = null;;
    url = url.replace(':id', id);
    return this.sharedService.getData(url);
  }
  updateMyProfileInfo(data): Observable<any> {
    let url = null;;
    url = url.replace(':id', data.id);
    return this.sharedService.patchData(url, data);
  }
  myAddressList(data): Observable<any> {
    const url = null;;
    return this.sharedService.postData(url, data);
  }
  getSingleAddress(id): Observable<any> {
    let url = null;;
    url = url.replace(':id', id);
    return this.sharedService.getData(url);
  }
  createSingleAddress(data): Observable<any> {
    const url = null;;
    return this.sharedService.postData(url, data);
  }
  updateMyAddressInfo(data): Observable<any> {
    let url = null;;
    url = url.replace(':id', data.id);
    return this.sharedService.patchData(url, data);
  }
  getMyInvoiceList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getMyTenantsList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getAllTenantsList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getMyInvoiceSingle(id): Observable<any> {
    let url = null;;
    url = url.replace(':id', id);
    return this.sharedService.getData(url);
  }
  getMyInvoiceLines(data): Observable<any> {
    let url = null;;
    url = url.replace(':id', data.id);
    return this.sharedService.postData(url,data);
  }
  
  getMySubscriptionList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getMySubscriptionSingle(id): Observable<any> {
    let url = null;;
    url = url.replace(':id', id);
    return this.sharedService.getData(url);
  }

  getMyQuotationList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getMyOrdersList(data): Observable<any> {
    let url = null;;
    url = url.replace(':page', data.page).replace(':limit', data.limit);
    return this.sharedService.postData(url, data);
  }
  getMyOrderQuotationSingle(id): Observable<any> {
    let url = null;;
    url = url.replace(':id', id);
    return this.sharedService.getData(url);
  }

  getCalenderInfo(id, uuid): Observable<any> {
    let getCalenderInfo = null;;
    getCalenderInfo = `${getCalenderInfo}?uuid=${uuid}`;
    return this.sharedService.getData(getCalenderInfo);
  }

  exportEvent(payload: any) {
    const url = null;;
    return this.http.post<Blob>(url, payload, { responseType: 'blob' as 'json' });
  }

  getAllCalenderInfo(payload): Observable<any> {
    const getAllCalenderInfo = null;;
    return this.sharedService.postData(getAllCalenderInfo, payload);
  }

  removeEventInfo(payload): Observable<any> {
    const removeCalenderInfo = null;;
    return this.sharedService.postData(removeCalenderInfo, payload);
  }

  addCalenderInfo(payload): Observable<any> {
    const addUpdateCalenderInfo = null;;
    return this.sharedService.postData(addUpdateCalenderInfo, payload);
  }

  getHrmsMetaInfo(): Observable<any> {
    const getHrmsMetaDatatUrl = null;;
    return this.sharedService.getData(getHrmsMetaDatatUrl);
  }

  updateCalenderInfo(payload): Observable<any> {
    const addUpdateCalenderInfo = null;;
    return this.sharedService.putData(addUpdateCalenderInfo, payload);
  }

  getAssociatedInfo(type) {
    const url = null;;
    return this.sharedService.postData(url , {types: type});
  }

  getUsers(data, staff=false): Observable<any> {
    let getUserUrl = null;;
    getUserUrl = getUserUrl.replace(':page', data.page).replace(':limit', data.limit);
    if(staff) {
      getUserUrl = `${getUserUrl}&is_staff=true`
    }
    return this.sharedService.getData(getUserUrl);
  }

  getGooglePayload(filterData, range) {
    const data = { start: null, end: null };
    if (filterData && filterData.start_date_range) {
      data.start = filterData.start_date_range.start;
    }
    else {
      data.start = range["start"];
    }
    if (filterData && filterData.end_date_range) {
      data.end = filterData.end_date_range.end;
    }
    else {
      data.end = range["end"];
    }

    return data;
  }

  getCompare(key, varA, varB,a,b){
    let comparison = 0;
    if(key === 'subject') {
      const dateA = (new Date(a['start_date'])).getTime();
      const dateB = (new Date(b['start_date'])).getTime();
      if (dateA > dateB) {
        comparison = 1;
      } else if (dateA < dateB) {
        comparison = -1;
      }
    }
    else {
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
    }
    return comparison;
  }

  // count dashboard
  getProviderPatientcount(response, from, to) {
    return this.sharedService.getData(`${config.getProviderPatientcount}?showAllData=${response}`);
  }
  getGendersCount(response, from, to) {
    return this.sharedService.getData(`${config.getAllgendercount}?showAllData=${response}`)
  }
  // end

  // getShiftPlanndUnplanned
  getAllShifts(fromDate: any, toDate: any,response) {
    const url =
     (` ${config.getShiftPlanndUnplanned}?showAllData=${response}`)+
      "&fromDate=" +
      fromDate +
      "&toDate=" +
      toDate
    return this.sharedService.getData(url);
  }
  getLoggedUserTypesInCurrentClinic(isAllClinic){
    const url = `${config.getLoggedUserTypesInCurrentClinic}?isAllClinic=${isAllClinic}`;
    return this.sharedService.getData(url);
  }
  changeUserRole(data){
    const url = `${config.loginRole}${data.id}?userTypeId=${data.userTypeId}`;
    return this.sharedService.putData(url,data);
  }
}
