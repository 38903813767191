import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedRoles = route.data.expectedRoles;
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    if(remD.userType == undefined){
      this.router.navigate(['/login']);
    }
    const userRole = remD.userType.type;
    if (!expectedRoles.includes(userRole)) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}