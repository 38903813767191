import { HttpClient } from '@angular/common/http';
import { config } from './../../config';
import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';
import { forkJoin, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {

  constructor(
    public sharedService: SharedService,
    readonly messageService: MessageService,
    public http: HttpClient,
    ) { }
  
}
