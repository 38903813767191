<ion-app>
  <div [class.full-view]="fullView" class="d-flex fullViewDefault">
    <app-sidebar  (myInputChange)="getInputData($event)" [myInput]="makeSidebarVisible" *ngIf="isSidebarVisible && !urlToOpen && !isLoginPage()" [navState]="fullView" [totalCount]="totalCount"></app-sidebar>
    <div style="width: 100%;" [ngClass]="{'app-content': !urlToOpen && !isLoginPage()}">
      <app-header  (myOutput)="getData($event)" [myOutputChange]="makeSidebarVisible" [rolesList]="rolesList"></app-header>
      <router-outlet></router-outlet>
      <!-- <app-footer *ngIf="!urlToOpen"></app-footer> -->
     
    </div>
  </div>
</ion-app>