export const MexicanLanguage = {
    title: "Mex- Good Morning",
    subTitle: "Mex- Welcome to Therapy Beyond.",

    dashProvider: "Mex- Provider",
    dashPatients: "Mex- Patients",
    dashTotalShifts: "Mex- Total Shifts",
    dashPlannedShift: "Mex- Planned Shift vs Unplanned Shift",
    dashChartUnPlannedShift: "Mex- Unplanned Shift",
    dashChartPlannedShift: "Mex- Planned Shift",
    dashPatientsGender: "Mex- Patients by Gender",
    dashSun: 'Mex- Sun',
    dashMon: 'Mex- Mon',
    dashTue: 'Mex- Tue',
    dashWed: 'Mex- Wed',
    dashThu: 'Mex- Thu',
    dashFri: 'Mex- Fri',
    dashSat: 'Mex- Sat',
    dashMale: 'Mex- Male',
    dashFemale: 'Mex- Female',

    lblAdd : 'Mex- Add',
    lblEdit : 'Mex- Edit',
    lblReplan : 'Mex- Replan',
    lblUpdate : 'Mex- Update',

    lblManagerName : 'Mex- Manager Name',
    lblManagerEmail : 'Mex- Manager E-Mail ID',
    lblClinicName : 'Mex- Clinic Name',
    lblClinicNo : 'Mex- Clinic No.',
    lblStreetAddress : 'Mex- Street Address',
    lblClinicAddress : 'Mex- Clinic Address',
    lblCity : 'Mex- City',
    lblState : 'Mex- State',
    lblZip : 'Mex- Zip Code',
    lblCountry : 'Mex- Country',
    lblAction: 'Mex-  Action',
    lblSearchName: 'Mex- Search Name',
    lblSelectClinic:'Mex-Select Clinic',

    lblDashboard : 'Mex-Dashboard',
    lblSolverName : 'Solver Name',
    lblClinic : 'Mex-Clinic',
    lblSkills : 'Mex-Skills',
    lblSkill : 'Mex-Skill',
    lblPlanner : 'Mex-Planner',
    lblPlanners : 'Mex-Planners',
    lblProvider : 'Mex-Provider',
    lblProviders : 'Mex-Providers',
    lblSpots : 'Mex-Spots',
    lblContracts : 'Mex-Contracts',
    lblSolver : 'Mex-Solver',
    lblPatient : 'Mex-Patient',
    lblPatients : 'Mex-Patients',
    lblScheduling : 'Mex-Scheduling',
    lblUnAvailability : 'Mex- Un Availability',
    lblGroups : 'Mex-Groups',
    lblProviderAddress:'Mex Provider Address',

    lblDeleteHeader : 'Mex-Delete Confirmation',
    lblDeleteInfo : 'Mex- Are you sure, you want to delete',
    lblYes : 'Mex-Confirm',
    lblNo : 'Mex-Cancel',
    lblClose : 'Mex-Close',
    lblNoSkills : 'Mex-No Skills Found',
    lblNoClinic : 'No Clinics Found',
    lblNoPlanner : 'No Planner Found',
    lblNoPatient:'Mex-No Patient Found',
    lblName : 'Mex-Name',
    lblEmailID : 'Mex-Email',
    lblMobileNo : 'Mex-Mobile No.',
    lblFirstName : 'Mex-First Name',
    lblLastName : 'Mex-Last Name',
    lblMale : 'Mex-Male',
    lblFemale : 'Mex-Female',
    lblClinics : 'Mex-Clinics',
    lblShowing : 'Mex Showing',
    lblDelete:'Mex-Delete',
    lblPatientCrId:'Mex-Patient CR Id',
    lblPatientStartDate:'Mex-Patient Start Date',
    lblPatientEndDate:'Mex-Patient End Date',
    lblAggressiveness:'Mex-Aggressiveness',
    lblShifts:'Mex-Shifts',
    lblPatientIDNo:'Mex Patient ID No',
    lblOf:'Mex of',
    lblSearchClinics : 'Mex-Search Clinics',
    lblDelLblInfo : 'Mex- Are you sure, you want to delete this record?',
    lblEmployeeIDNo : 'Mex-Employee ID No.',
    lblContract : 'Mex-Contract',
    lblNoProvider : 'Mex-No Provider Found',
    lblView:'Mex-View',

    lblSpotsName:'Mex Spots Name',
    lblSpotDuration:'Mex Spot Duration',
    lblGroupofSkills:'Mex Group of Skills',
    lblSessionLimit:'Session Limit',
    lblNoSpots:'Mex No Spots Found',

    lblScheduleName:'Mex Schedule Name',
    lblStartDate:'Mex Start Date',
    lblEndDate:'Mex End Date',
    lblStatus:'Mex Status',
    lblPlan:'Mex-Plan',

    lblContractsName:'Mex Contracts Name',
    lblMaxMinPerDay:'Mex Max Min Per Day',
    lblMaxMinPerWeek:'Mex Max Min Per Week',
    lblMaxMinPerMonth:'Mex Max Min Per Month',
    lblNoContract:'Mex No Contract Found',
    lblPerDayShift:'Mex Per Day Shift',
    lblPerWeekShift:'Mex Per Week Shift',
    lblAdditionalSkills:'Mex Additional Skills',
    lblShiftDate:'Mex Shift Date',
    lblShiftStartTime:'Mex Shift Start Time',
    lblShiftEndTime:'Mex Shift End Time',
    lblAssignedProvider:'Mex Assigned Provider',
    lblRecurrenceInterval:'Mex Recurrence Interval',
    lblIsPinned:'Mex Is Pinned',
    lblType:'Mex Type',
    lblAvailabilityStartDate:'Mex Availability Start Date',
    lblAvailabilityEndDate:'Mex Availability End Date',
    lblStartTime:'Mex Start Time',
    lblEndTime:'Mex End Time',
    lblAllDay:'Mex All Day',
    lblReason:'Mex Reason',
    lblDay:'Mex Day',
    lblWeek:'Mex Week',
    lblMonth:'Mex Month',
    lblPublish:'Mex Publish',
    lblPatientAddress:'Mex Patient Address',
    lblAggerssiveness:'Mex Aggerssiveness'

}
