import { Utils } from './../../utils';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { MessageService } from 'primeng/api';
import { Component, OnInit, ViewChild, ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Login } from '../autheticate.model';
import { AuthenticateService } from '../authenticate.service';
import { AuthUtils } from '../autheticate.utils';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './../authenticate.scss'],
})
export class LoginComponent implements OnInit,OnDestroy {
  loginModel: Login;
  captchaEnabled = false;
  env = environment;
  isTextFieldType: boolean;
  isMobileView = false;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  publicData = null;
  agentName: string;
  imageOne = true;
  imageTwo=false;
  imageThird = false;
  slideIndex = 0;
  myTimeout:any;
  constructor(
    public router: Router,
    public authService: AuthenticateService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public authUtils: AuthUtils,
    public appService: AppService,
    public utils: Utils,
    private ngZone:NgZone,
  ) {
    this.loginModel = new Login();
    this.isMobileView = window.innerWidth <= 600;
  }

  ngOnInit() {
    let data = localStorage.getItem('rem_d_planner');
    this.mapData(data);
    // if (data) {
    //   data = JSON.parse(data);
    //   const rememberMe = (data["remember_me"] === 1);
    //   if (rememberMe) {
    //     this.loginModel.email = data["email"];
    //     this.loginModel.password = data["password"];
    //     this.loginModel.remember_me = (data["remember_me"] === 1);
    //   }
    // }
  }

  ngAfterViewInit(){
    if(!this.isMobileView && !this.isTabView){
      this.showSlides();
      this.myTimeout = setInterval(()=>{// Change image every 4 seconds
        this.showSlides();
      }, 4000);
    }
    
  }

 ngOnDestroy(): void {
  clearInterval(this.myTimeout);
  
  // this.showSlides();
 }

  mapData(data){
    this.publicData = {
       "company_name": 'Opta Planner',
           "company_title": 'Sign In To Admin',
           "company_logo": '../../../assets/images/Web-Logo@2x.png',
           "company_fav_icon": '../../../assets/images/Web-Logo@2x.png'};
    document.title = this.publicData.company_name;
  }

  /**
   * To login user
   * @param formData Login Form Data 
   */
  login(formData) {
    formData['email'] = formData?.email?.toLowerCase();
    formData['remember_me'] = formData['remember_me'] ? 1 : 0;
    // localStorage.setItem('rem_d_planner', JSON.stringify(formData));
    // localStorage.setItem('optplanner_user', JSON.stringify(formData));
    // this.router.navigate(['/dashboard/']);
    const payload ={
      "password": formData['password'],
      "email": formData['email']
    }

    if(formData['password'] && formData['email']){
      this.appService.showCustomLoader(true);
      this.authService.login(payload).subscribe((response: any) => {
        this.appService.showCustomLoader(false);
          if(response.body.data){
            // setInterval(()=>{
            //   this.refreshToken();
            // }, 1500000);//1500000 miliseconds = 25 minuts
          }
          var isLoggedInUser = response.body.data
          console.log(response.body.data);
          
        localStorage.setItem('rem_d_planner', JSON.stringify(formData));
        localStorage.setItem('optplanner_user', JSON.stringify(response.body.data));
        let UserDataGet = JSON.parse(localStorage.getItem('optplanner_user'));
        clearInterval(this.myTimeout);
          this.authService.getUserTypesInCurrentClinic(false).subscribe((response) =>{
            let loggedInUserDataGet = JSON.parse(localStorage.getItem('optplanner_user'));
            let currentype = response.data.filter((item)=> item.type === UserDataGet.highestUserType)
            loggedInUserDataGet.userType = currentype[0];
            localStorage.setItem('optplanner_user', JSON.stringify(loggedInUserDataGet));
            this.messageService.add({ severity: 'success', summary: 'Success', detail: `${this.toTitleCase(loggedInUserDataGet.highestUserType)} logged in successfully` });
            this.ngZone.run(()=>{
              this.router.navigate(['/dashboard/']);
            })
          })
        }, (error) => {
        this.appService.showCustomLoader(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: (error.error.message === 'The Password or User ID you entered is incorrect.') ? 'The Password or Email you entered is incorrect.' : error.error.message });
          this.captchaEnabled = false;
        });
    }
  }

  /**
   * To Refresh Login Token
   */
  //  refreshToken(){
  //   const remD = JSON.parse(localStorage.getItem('optplanner_user'));
  //   this.authService.refreshLoginTokenAPI().subscribe((response: any) => {
  //     remD.token = response.data.token;
  //     localStorage.setItem('optplanner_user', JSON.stringify(remD));

  //   }, (error) => {
  //     console.log(error);
  //     });
  // }


  /**
   * To enable button on captcha success
   * @param event Captcha success
   */
  onCaptchaSuccess(event) {
    this.captchaEnabled = true;
  }

  /**
   * On captcha expire
   */
  onCaptchaExpire() {
    this.captchaEnabled = false;
  }
  togglePasswordFieldType(event){
    this.isTextFieldType = !this.isTextFieldType;
    event.preventDefault()
  }
  showSlides() {
    let i;
    let slides = Array.from(document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>);
    let dots = Array.from(document.getElementsByClassName('dot') as HTMLCollectionOf<HTMLElement>);

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";  
    }

    this.slideIndex++;

    if (this.slideIndex > slides.length) {      
      this.slideIndex = 1
    }    
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex-1].style.display = "block";
    
    dots[this.slideIndex-1].className += " active";
  }
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
