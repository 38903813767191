const defaultPerm = false;
export const assignedPermsSideBar = {
    "user_accounts": {
        "user_accounts": defaultPerm,
    },
    "subscription": {
      "all_tenants": defaultPerm,
      "roles_for_plan": defaultPerm,
      "plan_template": defaultPerm,
      "payments": defaultPerm,
      "payment_gateway": defaultPerm,
      "tags": defaultPerm,
      "invoicing": defaultPerm,
      "sales_teams": defaultPerm,
      "subscription_quotations": defaultPerm,
      "subscription_products": defaultPerm,
      "subscription_plans": defaultPerm,
      "all_subscriptions": defaultPerm,
    },
    "customers": {
      "all_customers": defaultPerm
    }
};
