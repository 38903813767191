import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class InitialAuthGuard implements CanActivate {
    constructor(
        readonly router: Router
    ) { }
    canActivate(): boolean {
        if (localStorage.getItem('optplanner_user') &&
        (localStorage.getItem('optplanner_user') !== "null")
        ) {            
            return true;
        } else {                                      
            this.router.navigate(['login']);
            return true;
        }
    }
}
