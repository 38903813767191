import { environment } from './../../environments/environment';
import { AppService } from './../app.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()

export class AppInterceptor implements HttpInterceptor {
    constructor(
        public router: Router,
        public appService: AppService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
       if (request.url == 'http://66.34.223.36:8090/api/v1/solver' || request.url == 'http://66.34.223.36:8090/api/v1/notification/user/all?today=false'
            || request.url !== 'http://66.34.223.36:8090/api/v1/notification/user/all') {
        }
        else if(request.url === 'http://66.34.223.36:8090/api/v1/notification/user/all'){
            this.appService.showLoader(false);
            this.appService.showCustomLoader(false);
        }
        else{
            this.appService.showLoader(true);
        }
        let requestToHandle = null;
        const data = localStorage.getItem('optplanner_user');
        const authToken = (data && (data !== 'null')) ? JSON.parse(data).token : '';
        
            requestToHandle = authToken ? request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${authToken}`)
                }) : request;
        return next.handle(requestToHandle).pipe(tap((event: HttpEvent<any>) => {
            if ((event instanceof HttpResponse) && (event.status && (event.status === 200 || 201))) {
                    setTimeout(() => {
                        this.appService.showLoader(false);
                    }, 0);
            }
        }, (error) => {
            if (error instanceof HttpErrorResponse) {
                this.appService.showLoader(false);
                this.appService.showCustomLoader(false);
                if (error.status === 401 || error.status === 0) {
                    this.router.navigate(["/login"],{ skipLocationChange: true });
                } else {
                    return throwError(error);
                }
              }
        }));
    }
}


