<form #resetPasswordForm="ngForm" (ngSubmit)="forgotPassword(resetPasswordForm)" class="flipInX">
  <div class="login">
    <div class="login-header-img">
      <img src="../../../assets/images/Web-Logo@2x.png" alt="logo">
    </div>
    <div class="forgetPass setBoxStyle">
      <h4 class="highReset" style="text-align: center;">Recover Password</h4>
      <div class="vaildation-area user-data">
        <div class="col-md-12 user-field">
          <ion-label class="label required">Email</ion-label>
          <ion-input class="user-input" type="email" required #email="ngModel" name="email" [(ngModel)]="resetEmail"
            >
          </ion-input>
          <div class="vaiidate-field" *ngIf="email.errors && ( email.touched || resetPasswordForm.submitted)">
            <p [hidden]="!email.errors.required">Email is required</p>
            <p [hidden]="!email.errors.pattern">Please enter a valid email</p>
          </div>
        </div>
      </div>
      <div class="setWidth">
        <!-- <ion-button class="btn-main setWidth" (click)="forgotPassword(resetEmail)">Request</ion-button> -->
        <button  type="submit" class="forget-req-btn" >Request</button>
        <ion-button class="btn-default setWidth" [routerLink]="['/login']" style="margin-left:0 !important;">Cancel</ion-button>
      </div>
    </div>
  </div>
</form>

<p-dialog 
styleClass="mt"
position="center" *ngIf="mailSent" [draggable]="false" [visible]="mailSent"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span class="close-pop" (click)="mailSent = false;"><img src="assets/images/close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <div class="mCenter">
        <img class="successImg" src="../../../assets/images/Asset 1@2x.png" alt="logo">
      </div>
      <div class="vaildation-area dropdown-top fColor">
        An email has been sent to your registered Email with a link to reset the password.
      </div>
    </div>
  </form>

  <p-footer class="btns">
    <div class="mCenter setOkStyle">
      <ion-button class="btn-save-radius" (click)="tologin()">OK
      </ion-button>
    </div>
  </p-footer>
</p-dialog>
