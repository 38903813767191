export const EnglishLanguage = {
    title: "",
    subTitle: "Welcome to Therapy & Beyond.",
    dashProvider: "Provider",
    dashPatients: "Patients",
    dashTotalShifts: "Total Shifts",
    dashPlannedShift: "Planned Shift vs Unplanned Shift",
    dashChartUnPlannedShift: "Unplanned Shift",
    dashChartPlannedShift: "Planned Shift",
    dashPatientsGender: "Patients by Gender",
    dashSun: 'Sun',
    dashMon: 'Mon',
    dashTue: 'Tue',
    dashWed: 'Wed',
    dashThu: 'Thu',
    dashFri: 'Fri',
    dashSat: 'Sat',
    dashMale: 'Male',
    dashFemale: 'Female',
    lblDelete:'Delete',
    lblView:'View',
    lblPatientCrId:'Patient CR Id',
    lblPatientStartDate:'Patient Start Date',
    lblPatientEndDate:'Patient End Date',
    lblAggressiveness:'Aggressiveness',
    lblShifts:'Shifts',
    lblPatientIDNo:'Patient Name',


    lblAdd : 'Add',
    lblEdit : 'Edit',
    lblReplan : 'Replan',
    lblUpdate : 'Update',
    lblNoPatient:'No Patient Found',
    lblManagerName : 'Manager Name',
    lblManagerEmail : 'Manager E-Mail ID',
    lblClinicName : 'Clinic Name',
    lblClinicNo : 'Clinic No.',
    lblStreetAddress : 'Street Address',
    lblClinicAddress : 'Clinic Address',
    lblCity : 'City',
    lblState : 'State',
    lblZip : 'Zip Code',
    lblCountry : 'Country',
    lblAction: ' Action',
    lblSearchName: 'Search Name',
    lblDashboard : 'Dashboard',
    lblClinic : 'Clinic',
    lblSkills : 'Skills',
    lblSkill : 'Skill',
    lblPlanner : 'Planner',
    lblPlanners : 'Planners',
    lblProvider : 'Provider',
    lblProviders : 'Providers',
    lblSpots : 'Session Type',
    lblContracts : 'Contracts',
    lblSolver : 'Solver',
    lblSolverName : 'Solver Name',
    lblPatient : 'Patient',
    lblPatients : 'Patients',
    lblScheduling : 'Scheduling',
    lblUnAvailability : 'Unavailability',
    lblGroups : 'Groups',
    lblDeleteHeader : 'Delete Confirmation',
    lblDeleteInfo : 'Are you sure, you want to delete',
    lblYes : 'Confirm',
    lblNo : 'Cancel',
    lblClose : 'Close',
    lblNoSkills : 'No Skills Found',
    lblNoClinic : 'No Clinics Found',
    lblNoPlanner : 'No Planner Found',
    lblNoProvider : 'No Provider Found',
    lblNoSpots:'No Session Type Found',
    lblProviderAddress:' Provider Address',

    lblName : 'Name',
    lblEmailID : 'Email',
    lblMobileNo : 'Mobile No.',
    lblFirstName : 'First Name',
    lblLastName : 'Last Name',
    lblMale : 'Male',
    lblFemale : 'Female',
    lblClinics : 'Clinics',
    lblShowing : 'Showing',
    lblOf:'of',
    lblSearchClinics : 'Search Clinics',
    lblSelectClinic:'Select Clinic',
   

    lblDelLblInfo : ' Are you sure, you want to delete this record?',
    lblEmployeeIDNo : 'Employee Type',
    lblContract : 'Contract',

    lblSpotsName:'Session Name',
    lblSpotDuration:'Session Duration',
    lblSessionLimit:'Session Limit',
    lblGroupofSkills:'Group of Skills',

    lblScheduleName:'Schedule Name',
    lblStartDate:'Start Date',
    lblEndDate:'End Date',
    lblStatus:'Status',
    lblPlan:'Plan',

    lblContractsName:'Contracts Name',
    lblMaxMinPerDay:'Maximum Minute Per Day',
    lblMaxMinPerWeek:'Maximum Minute Per Week',
    lblMaxMinPerMonth:'Maximum Minute Per Month',
    lblNoContract:'No Contract Found',
    lblPerDayShift:'Per Day Shift',
    lblPerWeekShift:'Per Week Shift',

    lblAdditionalSkills:'Additional Skills',
    lblShiftDate:'Shift Date',
    lblShiftStartTime:'Shift Start Time',
    lblShiftEndTime:'Shift End Time',
    lblAssignedProvider:'Assigned Provider',
    lblRecurrenceInterval:'Recurrence Interval',
    lblIsPinned:'Is Pinned',


    lblType:'Type',
    lblAvailabilityStartDate:'Unavailability Date',
    lblAvailabilityEndDate:'Availability End Date',
    lblStartTime:'Start Time',
    lblEndTime:'End Time',
    lblAllDay:'All Day',
    lblReason:'Reason',
    lblDay:'Day',
    lblWeek:'Week',
    lblMonth:'Month',
    lblPublish:'Publish',
    lblPatientAddress:'Patient Address',
    lblAggerssiveness:'Aggerssiveness'
    // lbl : '',
    // lbl : '',
    // lbl : '',
    // lbl : '',
    // lbl : '',
    // lbl : '',
    // lbl : '',
    // lbl : '',
}
