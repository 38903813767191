<form #resetPasswordForm="ngForm" class="flipInX">
  <div class="login resetPass">
    <div class="login-header-resetpassword">
      <img src="../../../assets/images/Web-Logo@2x.png" alt="logo">
    </div>
    <div class="forgetPass setBoxStyle">
      <h4 class="highReseth marginbotm" style="text-align: center;">Reset Password</h4>
      <div class="vaildation-area user-data">
        <!-- <div class="col-md-12 user-field">
          <ion-label class="label">Email</ion-label>
          <ion-item>
            <ion-input class="user-input" type="email" required #emailuser="ngModel" name="email" [(ngModel)]="email"
              pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$">
            </ion-input>
          </ion-item>
          <div class="vaiidate-field" *ngIf="emailuser.errors && ( emailuser.touched || resetPasswordForm.submitted)">
            <p [hidden]="!emailuser.errors.required">Email is required</p>
            <p [hidden]="!emailuser.errors.pattern">Enter email in correct format</p>
          </div>
        </div> -->
        <div class="col-md-12 user-field">
          <ion-label class="label">New Password</ion-label>
          <ion-item>
            <ion-input class="user-input" [type]="isTextFieldType ? 'text': 'password'" required #newPasswords="ngModel" name="newPassword"
              [(ngModel)]="newPassword" pattern="(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"></ion-input>
            <button 
            type="button"
            (click)="togglePasswordFieldType()"
              style="z-index:1000;position: absolute;left: 360px;margin-top: 2.5%; background-color: transparent;">
              <ion-icon [name]="isTextFieldType ? 'eye' : 'eye-off'">
              </ion-icon>
            </button>
          </ion-item>
          <div class="vaiidate-field" *ngIf="newPasswords.errors && ( newPasswords.touched || resetPasswordForm.submitted)">
            <p [hidden]="!newPasswords.errors.required">Password is required</p>
            <p [hidden]="!newPasswords.errors.pattern">Password should have atleast 1 small-case letter,
              1 Capital letter, 1 digit, 1 special character and the
              length should be between 8-12 characters.</p>
          </div>
        </div>
        <div class="col-md-12 user-field">
          <ion-label class="label">Confirm Password</ion-label>
          <ion-item>
            <ion-input class="user-input" [type]="isTextFieldConfirm ? 'text': 'password'" required #confirmPassword="ngModel" name="confirmPassword"
              [(ngModel)]="confirmPasswords"></ion-input>
            <button (click)="togglePasswordFieldConfirm()"
              type="button"
              style="z-index:1000;position: absolute;left: 360px;margin-top: 2.5%; background-color: transparent;">
              <ion-icon [name]="isTextFieldConfirm ? 'eye' : 'eye-off'">
              </ion-icon>
            </button>
          </ion-item>
          <div class="vaiidate-field" *ngIf="confirmPassword.errors && ( confirmPassword.touched || resetPasswordForm.submitted)">
            <p [hidden]="!confirmPassword.errors.required">Password is required</p>
            <p [hidden]="!confirmPassword.errors.pattern">Password should have atleast 1 small-case letter,
              1 Capital letter, 1 digit, 1 special character and the
              length should be between 6-12 characters.</p>
          </div>
          <!-- <div class="vaiidate-field" *ngIf="newPasswords !== confirmPassword">
            <p>Password must match</p>
          </div> -->
        </div>
      </div>
      <div class="setWidth">
        <ion-button class="btn-main setWidth" (click)="resetPassword()">Reset</ion-button>
      </div>
    </div>
  </div>
</form>
<p-dialog 
styleClass="mt"
position="center" *ngIf="mailSent" [draggable]="false" [visible]="mailSent"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span class="close-pop" (click)="toLogin()"><img src="assets/images/close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <div class="mCenter">
        <img class="successImg" src="../../../assets/images/Asset 1@2x.png" alt="logo">
      </div>
      <div class="vaildation-area dropdown-top fColor">
        Your password has been successfully reset. Please login and continue to access your account
      </div>
    </div>
  </form>
  <p-footer class="btns">
    <div class="mCenter setOkStyle">
      <ion-button class="btn-save-radius" (click)="toLogin()">OK
      </ion-button>
    </div>
  </p-footer>
</p-dialog>